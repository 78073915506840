<template>
  <div class="main">
    <div class="newTop">
        <div class="newTop_left">
            <p>ROSSO 国际艺术教育</p>
            <div>Fashion&Art Education</div>
        </div>
        <div class="newTop_right">
            <img src="../assets/newTopLogo.png" alt="">
            <p>留学咨询：</p>
            <div>400-0999-530</div>
        </div>
        
    </div>

    <div class="newTop_middle">
        <div class="mid_left">
            <div class="music_title">音乐留学中心</div>
            <div class="m_li">
                <div class="li" id="liFirst" @mouseenter="mouse1(0)" style="padding-left:0;color: #2ca6e0;">上海(总部)</div>
                <div class="li" @mouseenter="mouse1(1)">北京</div>
                <div class="li" @mouseenter="mouse1(2)">深圳</div>
                <div class="li" @mouseenter="mouse1(3)">广州</div>
                <div class="li" @mouseenter="mouse1(4)">成都</div>
            </div>
            <div class="location">
                <img src="../assets/locationLogo.png" alt="">
                <div id="show1">上海徐汇区文定路208号A座</div>
            </div>

            <div class="music_title">艺术留学中心</div>
            <div class="m_li">
                <div class="li2" @mouseenter="mouse2(0)" style="padding-left:0;color: #2ca6e0;">上海(总部)</div>
                <div class="li2" @mouseenter="mouse2(1)">北京</div>
                <div class="li2" @mouseenter="mouse2(2)">广州</div>
                <div class="li2" @mouseenter="mouse2(3)">深圳</div>
                <div class="li2" @mouseenter="mouse2(4)">杭州</div>
                <div class="li2" @mouseenter="mouse2(5)">南京</div>
                <div class="li2" @mouseenter="mouse2(6)">成都</div>
                <div class="li2" @mouseenter="mouse2(7)">武汉</div>
                
            </div>
            <div class="location">
                <img src="../assets/locationLogo.png" alt="">
                <div id="show2">上海徐汇区文定路208号A座</div>
            </div>
        </div>

        <div class="mid_mid">
            <p class="p_title">联系我们</p>
            <div class="hrtype">
                <img src="../assets/joinus.png" alt="">
                <p>加入我们 |</p>
                <div>hr@rossoarts.com</div>
            </div>
            <div class="hrtype">
                <img src="../assets/joinus.png" alt="">
                <p>商务合作 |</p>
                <div>mk@rossoarts.com</div>
            </div>
            <div class="hrtype">
                <img src="../assets/joinus.png" alt="">
                <p>投诉建议 |</p>
                <div>advice@rossoarts.com</div>
            </div>
        </div>
        <div class="mid_right">
            <img src="../assets/bottomCode.png" alt="">
            <p>加老师微信了解更多留学咨询</p>
        </div>
    </div>

    <div class="last_bottom">
       沪ICP备19007600号-2 © 2019-2022 上海洛素文化艺术有限公司 版权所有
    </div>

  </div>
</template>

<script>
export default {
    data(){
        return {
            li1:["上海徐汇区文定路208号A座","北京东城区南竹杆胡同2号银河SOHOB座","深圳福田区金田路东福华路北岗厦皇庭大厦","广州市天河区中信广场7308室","成都锦江区成都国际金融中心IFS一号楼"],
            li2:["上海徐汇区文定路208号A座","北京东城区南竹杆胡同2号银河SOHOB座","广州市天河区中信广场7308室","深圳福田区金田路东福华路北岗厦皇庭大厦","杭州西湖区学院路77号黄龙万科中心","南京秦淮区汉中路1号南京国际金融中心","成都锦江区成都国际金融中心IFS一号楼","武汉武昌区中北路9号长城汇",]
        }
    },
    created(){

    },
    methods:{
        mouse1(val){
            
            show1.innerHTML = this.li1[val]
            var lis = document.getElementsByClassName("li")
           
            for(var i=0;i<lis.length;i++){
                lis[i].style.color = "#999"
            }
            lis[val].style.color = "#2ca6e0"
            // liFirst.style.color = "#999"
        },
        mouse2(val){
            // console.log(val);
            show2.innerHTML = this.li2[val]
            var lis2 = document.getElementsByClassName("li2")
           
            for(var i=0;i<lis2.length;i++){
                lis2[i].style.color = "#999"
            }
            lis2[val].style.color = "#2ca6e0"
            // liFirst.style.color = "#999"
        }
    }

}
</script>

<style lang="less" scoped>
.main{
    width: 100%;
    // height: 388px;
    border: 1px solid transparent;
    background-color: #393939;
    margin: 0 auto;
}
.li:hover{
    color: #2ca6e0;
}
.last_bottom{
    
    margin-top: 16px;
    color: #999;
    font-size: 12px;
}
.newTop_middle{
    width: 1124px;
    // height: 237px;
    // border: 1px solid #000;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
    border-top: 1px solid hsla(0,0%,100%,.1);
    .mid_right{
        width: 160px;
        // height: 10px;
        // border: 1px solid hotpink;
        p{
            color: #999;
            font-size: 12px;
        }
        img{
            width: 140px;
            height: 140px;
        }
    }
    .mid_mid{
        width: 245px;
        // border: 1px solid #000;
        text-align: left;
        .hrtype{
            width: 245px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            font-size: 14px;
            p{
                color: #999;
                // border-right: 1px solid #999;
                // padding: 0 20px 0 0 ;
                
            }
            div{
                width: 150px;
                color: #fff;
            }
        }
        .p_title{
            font-size: 16px;
            color: #fff;
        }
    }
    
    .mid_left{
        width: 335px;
        // border: 1px solid blue;
        text-align: left;
        
        .location{
            height: 20px;
            display: flex;
            margin: 10px 0 20px 0;
            div{
            color: #2ca6e0;
            font-size: 14px;
            margin-left: 10px;
            }
        }
        .m_li{
            width: 335px;
            // height: 28px;
            display: flex;
            flex-wrap: wrap;
            color: #999;
            
            
            div{
                border-right: 1px solid #999;
                height: 18px;
                line-height: 18px;
                font-size: 14px;
                padding: 0 15px;
                margin-top: 10px;
                // border: 1px solid #000;
            }
        }
        .music_title{
            font-size: 16px;
            color: #fff;
            // padding: 10px 0;
        }
    }
}
.newTop{
    width: 1124px;
    height: 61px;
    // border: 1px solid #000;
    // border: 1px solid red;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .newTop_left{
        color: #fff;
        text-align: left;
        p{
            font-size: 20px;
        }
        div{
            font-size: 14px;
        }
    }

    .newTop_right{
        display: flex;
        align-items: center;
       
        img{
            width: 16px;
            margin-right: 10px;
        }
        
        p{
            font-size: 16px;
            color: #2ca6e0;
        }
        div{
            font-size: 20px;
            font-family: Impact;
            font-weight: 400;
            color: #2ca6e0;
        }
    }
}

</style>