import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant from 'vant';
import 'vant/lib/index.css';
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)

// import jquery from "jquery";
// Vue.prototype.$ = jquery;



import "swiper/swiper.min.css"
// import "../public/jquery-1.8.3.min.js"
// import "../public/sever.js"
// import "../public/test.js"


Vue.use(Vant);
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  //这里与vue.config.js中的事件名相对应
  mounted () {
    document.dispatchEvent(new Event('render-event'))

  },
  created(){
    
  }
}).$mount('#app')
