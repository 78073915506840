import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: "ROSSO音乐留学-专注音乐类留学作品集培训机构" }
  },
  {
    path: '/works',
    name: 'works',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/WorksView.vue')
    },
    meta: { title: "音乐留学作品集培训机构_ROSSO音乐留学" }
  },
  {
    path: '/teacher',
    name: 'teacher',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/TeacherView.vue')
    },
    meta: { title: "音乐留学导师_音乐留学作品集培训_ROSSO音乐留学" }
  },
  {
    path: '/colleges',
    name: 'colleges',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/CollegesView.vue')
    },
    meta: { title: "音乐留学院校_世界音乐学院排名_ROSSO音乐留学" }
  },
  {
    path: '/major',
    name: 'major',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/MajorView.vue')
    },
    meta: { title: "音乐留学专业_ROSSO音乐留学" }
  },
  {
    path: '/information',
    name: 'information',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/informationView.vue')
    },
    meta: { title: "音乐留学资讯_音乐留学申请规划_ROSSO音乐留学" }
  },
  {
    path: '/collegeDetail',
    name: 'collegeDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/collegeDetail.vue')
    },
    meta: { title: "音乐留学院校_世界音乐学院排名_ROSSO音乐留学" }
  },
  {
    path: '/majorDetail',
    name: 'majorDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/majorDetail.vue')
    },
    meta: { title: "音乐留学专业_ROSSO音乐留学" }
  },
  {
    path: '/inforDetail',
    name: 'inforDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/inforDetail.vue')
    },
    meta: { title: "音乐留学资讯_音乐留学申请规划_ROSSO音乐留学" }
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.afterEach(to => {
  document.title = to.meta.title
})

export default router
