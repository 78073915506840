<template>
    <div class="section5">
        <div class="s5_title">
            限时免费领取作品集试听课！ROSSO助你早日实现名校梦！
        </div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="姓名" class="formLableOne">
                <el-input v-model="formInline.name" placeholder="请输入您的姓名" id="nameRule"></el-input>
            </el-form-item>
            <el-form-item label="手机号" class="formLableOne">
                <el-input v-model="formInline.tel" placeholder="请输入您的手机号" id="telRule"></el-input>
            </el-form-item>
            <el-form-item label="意向专业" class="formLableOne">
                <el-select v-model="formInline.major" placeholder="意向专业">
                    <el-option label="现代器乐表演" value="现代器乐表演"></el-option>
                    <el-option label="现代声乐表演" value="现代声乐表演"></el-option>
                    <el-option label="音乐制作" value="音乐制作"></el-option>
                    <el-option label="音乐剧表演" value="音乐剧表演"></el-option>
                    <el-option label="作曲与指挥" value="作曲与指挥"></el-option>
                    <el-option label="古典器乐表演" value="古典器乐表演"></el-option>
                    <el-option label="声乐设计" value="声乐设计"></el-option>
                    <el-option label="音乐产业/管理" value="音乐产业/管理"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">立即预约</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import axios from "axios";
export default {

    data() {
        return {
            formInline: {
                name: '',
                tel: '',
                major: ''
            },
        }
    },
    methods: {
        onSubmit() {
            var currentURL = window.location.href;
            console.log(window.location.href,"rrrrrrr");
            var status = ''
            // 检查 URL 中是否包含某些关键词，来判断使用的搜索引擎
            if (currentURL.includes("google.com")) {
                status = "用户正在使用 Google 搜索引擎"
            } else if (currentURL.includes("bing.com")) {
                status = "用户正在使用 Bing 搜索引擎"
            } else if (currentURL.includes("yahoo.com")) {
                status = "用户正在使用 Yahoo 搜索引擎"
            } else if (currentURL.includes("baidu.com")) {
                status = "用户正在使用 百度 搜索引擎"
            } else {
                status = "无法确定用户使用的搜索引擎"
            }

            var patt = /^1[3-9][0-9]{9}$/

            if (this.formInline.name == "" || this.formInline.tel == "") {
                // console.log("姓名和手机号不能为空");
                this.$message.error('姓名和手机号不能为空');

            } else {
                // console.log("不是空");

                if (patt.test(this.formInline.tel)) {

                    // console.log("可以发请求");
                    // console.log(this.formInline);
                    axios.post("https://public.rossoarts.com/api/v1/submitForm", {
                        "name": this.formInline.name,
                        "tel": this.formInline.tel,
                        "major": this.formInline.major,
                        "url": window.location.href,
                        "status": status,
                    }).then((data) => {
                        // console.log(data,"response");
                        this.$message({
                            message: "提交成功",
                            type: 'success'
                        });
                     
                    }).catch(function (error) {
                        console.log(error, "error");
                        // this.$message.error(data.data.message);
                    });

                } else {
                    console.log("手机号格式不正确");
                    this.$message.error('手机号格式不正确');

                }

            }


        },
    }
}
</script>

<style lang="less" >
.formLableOne .el-form-item__label {
    color: white;
}

.section5 {
    width: 100%;
    height: 184px;
    background: url(../assets/s5bgc.png);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: white;

}

.s5_title {
    /* margin: 40px auto; */
    width: 100%;
    font-size: 20px;
}

.demo-form-inline {
    color: white;
}
</style>