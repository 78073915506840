<template>
    <div id="app">
        <div class="nav_top">
            <div class="nav_box">
                <div class="nav_left"><img src="./assets/logo.56956805.png" alt=""></div>

                <div class="">
                    <div class="nav_right">
                        <div class="nav_right_text">
                            <p>上海</p>
                            <span>|</span>
                            <p>北京</p>
                            <span>|</span>
                            <p>深圳</p>
                            <span>|</span>
                            <p>广州</p>
                            <span>|</span>
                            <p>成都</p>

                        </div>

                        <div class="nav_right_num">
                            
                                <img src="./assets/tel.png" alt="">
                            
                            <div>
                                400-0900-530
                            </div>
                        </div>
                    </div>
                    <div style="text-align: left;font-size: 14px;font-weight: bold;color: #000;">
                        要OFFER，更要能力，伴你至就业
                    </div>
                </div>

            </div>
        </div>

        <div class="rouLinkBox">
            <nav class="nav">
                <router-link class="router-link-active" to="/">首页</router-link>
                <router-link class="router-link-active" to="/works">作品集培训</router-link>
                <router-link class="router-link-active" to="/teacher">音乐导师</router-link>
                <router-link class="router-link-active" to="/colleges">音乐院校</router-link>
                <router-link class="router-link-active" to="/major">音乐专业</router-link>
                <router-link class="router-link-active" to="/information">音乐资讯</router-link>
                <a class="goRosso" href="https://public.rossoarts.com/">更多专业 > </a>
            </nav>
        </div>

        <div class="kefuBox">
            <a href="javascript:void(0)" onclick="ydtopenchat()">
                <img src="./assets/sericon.png" alt="" class="kefuIco">
                <p class="kefuText">在线咨询</p>
            </a>
        </div>

        <router-view />

        <!-- <TabBottom></TabBottom> -->
    </div>
</template>
<script>
import TabBottom from '@/components/TabBottom.vue'

export default {
    name: "HomeView",
    components: {
        // HelloWorld
        TabBottom
    },
    created() {

    },
    updated() {

        this.$parent.$el.scrollTop = 0;//表示获取router-view上一级的scrollTop
    },
    watch: {
        '$route': function (to, from) {
            // this.$parent.$el.scrollTop = 0
            document.documentElement.scrollTop = 0
            document.body.scrollTop = 0
        }
    }
}
</script>
<style lang="less">
.goRosso {
    color: #fff;
    display: inline-block;
    width: 110px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #2ca6e0;
    padding: 0 10px;
    white-space: nowrap;
}

.kefuBox {
    width: 60px;
    height: 70px;
    // border: 1px solid #000;
    position: fixed;
    top: 300px;
    right: 0;
    z-index: 100086;
    background-color: #1F8CEB;

    .kefuIco {
        width: 30px;
        margin-top: 8px;
        margin-bottom: 4px;
    }

    .kefuText {
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0.63px;
        text-align: center;
        margin: 0;
        margin-bottom: 4px;
    }
}

#icon_module {
    display: none;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow-x: hidden;

}

* {
    margin: 0;
    padding: 0;

}

.rouLinkBox {
    width: 100%;
    // border: 1px solid #000;
    background-color: #393939;
    position: fixed;
    top: 84px;
    z-index: 100;
}

.nav {

    // position: fixed;
    // top: 84px;
    // z-index: 11111;
    width: 1120px;
    // left: calc(50% - 562px);
    // border: 1px solid red;
    margin: 0 auto;
}


.nav_top {
    position: fixed;
    background-color: white;
    z-index: 100;
    width: 100%;
    top: 0;
    // border: 1px solid #000;
}

.nav_box {
    width: 1120px;
    // border: 1px solid #000;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav_right {
        width: 390px;
        display: flex;
        // height: 80px;
        align-items: center;
        justify-content: space-between;
    }

    .nav_right_text {
        width: 170px;
        display: flex;
        align-items: center;
        height: 30px;
        border-right: 1px solid #2ca6e0;
        color: #000;
        font-weight: bold;
        font-size: 14px;
        justify-content: space-between;
        padding: 0 25px 0 0;
    }

    .nav_right_num {
        display: flex;
        font-size: 24px;
        align-items: center;
        font-weight: bold;
        img{
            width: 24px;
            margin-right: 5px;
        }
    }
}

nav {

    height: 60px;
    // border: 1px solid #000;

    background-color: #393939;
    display: flex;
    padding: 0 0 0 0px;
    align-items: center;
    // border: 1px solid red;

    .router-link-active {
        line-height: 60px;
        padding: 0 20px;
        color: white;
    }


    a {
        // font-weight: bold;
        color: #2c3e50;
        height: 96%;
        // border: 1px solid #000;
        display: inline-block;
        text-decoration: none;
        margin: 0 25px;

        &.router-link-exact-active {

            color: rgb(44, 166, 224);
            border-bottom: 2px solid rgb(44, 166, 224);
        }
    }
}</style>
