<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

    <div class="block" style="background-size: 100%;background-repeat: no-repeat">
      <el-carousel height="470px">
        <el-carousel-item v-for="item in 3" :key="item">
          <!-- <h3 class="small">{{ item }}</h3> -->
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="introduce">
      <ul class="int_ul">
        <li>
          <div class="img1_div"></div>
          <div class="p1">国外TOP10院校师资力量</div>
          <div class="p2">
            云集海内外知名音乐专家、教授，搭档音乐学院海归导师，为每一位学员提供个性化授课服务
          </div>
        </li>
        <li>
          <div class="img2_div"></div>
          <div class="p1">学院式课程体系</div>
          <div class="p2">
            以学生为中心，注重多元化互动式教学体验，以1对1、多对1的形式提前让学生感受海外大学课程
          </div>
        </li>
        <li>
          <div class="img3_div"></div>
          <div class="p1">院校合作资源</div>
          <div class="p2">
            与伯克利音乐学院、茱莉亚学院等多所海外名校建立合作，整合优质国际音乐教育资源
          </div>
        </li>
        <li>
          <div class="img4_div"></div>
          <div class="p1">海内外音乐实践</div>
          <div class="p2">
            提供专业留学策划团队，为学员打造海内外竞赛、演出、实习、大师课等多品类音乐实践项目
          </div>
        </li>
      </ul>
    </div>

    <!-- 海外音乐学院式课程 -->
    <div class="section11">
      <img src="../assets/bt015f87595.png" alt="" style="margin-top: 40px" />

      <div class="section_box">
        <ul class="secton1_ul">
          <li class="type_one">
            <p class="s1_p1">01</p>
            <p class="s1_p2">基础课程学习</p>
            <p class="s1_p3">Foundation Practice</p>
            <p class="s1_p4">
              乐理课程，视唱练耳，基础软件制作等。通过对基础音乐能力的训练，帮助学生在后续的专业课学习中能更快适应，进入状态
            </p>

            <div class="trangle"></div>
          </li>
          <li class="type_two type_one">
            <div class="cover_liner"></div>
          </li>
        </ul>

        <ul class="secton1_ul">
          <li class="type_one">
            <p class="s1_p1">02</p>
            <p class="s1_p2">专业课程学习</p>
            <p class="s1_p3">Professional Course</p>
            <p class="s1_p4">
              进阶软件制作，进阶编曲，混音等。(音乐制作专业)
              与大学课程完全相符的课程难度和内容设置，以高标准激励学生提升专业能力
            </p>

            <div class="trangle"></div>
          </li>
          <li class="type_two type_one type4">
            <div class="cover_liner"></div>
          </li>
        </ul>

        <ul class="secton1_ul secton1_ul2">
          <li class="type_two type_one type5">
            <div class="cover_liner"></div>
          </li>
          <li class="type_one">
            <p class="s1_p1">03</p>
            <p class="s1_p2">1v1针对性强化</p>
            <p class="s1_p3">Private Instruction</p>
            <p class="s1_p4">
              针对考试及面试作品集，准备个人申请留学所需材料。专业导师同时在该阶段结合学生在基础和专业课程学习中的情况，进行针对性强化训练
            </p>

            <div class="trangle"></div>
          </li>
        </ul>
        <ul class="secton1_ul secton1_ul2">
          <li class="type_two type_one type7">
            <div class="cover_liner"></div>
          </li>
          <li class="type_one">
            <p class="s1_p1">04</p>
            <p class="s1_p2">乐队及合奏表演学习</p>
            <p class="s1_p3">Ensemble Practice</p>
            <p class="s1_p4">
              Ensemble学习是 Rosso
              Music的核心项目之一。学生将与其他Rosso音乐学员组成乐队，共同学习ensemble课程，加强团队表演中的音乐能力
            </p>

            <div class="trangle"></div>
          </li>
        </ul>

        <ul class="secton1_ul">
          <li class="type_one">
            <p class="s1_p1">05</p>
            <p class="s1_p2">行业实地学习体验</p>
            <p class="s1_p3">Industry Practice</p>
            <p class="s1_p4">
              学生将前往专业录音棚、 音乐厅等场所， 真实体验音乐行业的工作内容，
              丰富个人学习经验
            </p>

            <div class="trangle"></div>
          </li>
          <li class="type_two type_one type10">
            <div class="cover_liner"></div>
          </li>
        </ul>

        <ul class="secton1_ul">
          <li class="type_one">
            <p class="s1_p1">06</p>
            <p class="s1_p2">海外教授大师课</p>
            <p class="s1_p3">Professor Workshop</p>
            <p class="s1_p4">
              学生将参与海外教授大师课（伯克利、柯蒂斯、曼哈顿等名校的在职教授），接受院校面试官考前咨询。让每一位学员接受到国际化、专业化的指导
            </p>

            <div class="trangle"></div>
          </li>
          <li class="type_two type_one type12">
            <div class="cover_liner"></div>
          </li>
        </ul>
      </div>
      <!-- <a
        class="askBtn"
        target="_blank"
        href="https://www11c1.53kf.com/webCompany.php?arg=10168353&kf_sign=Dc2MjMTY2Ok1NTM4NTkwNDI0MzAwMTM3MjE2ODM1Mw%253D%253D&style=1"
      >
        立即咨询
      </a> -->
      <a href="javascript:void(0)" onclick="ydtopenchat()" class="askBtn">立即咨询</a>
    </div>

    <!-- 留学特色服务介绍 -->
    <div class="section12">
      <img src="../assets/paServe.png" alt="" style="margin-top: 80px" class="section2_imgtitle" />

      <div class="s2_main">
        <div class="s2_box1">
          <div class="s2_box1_cov">
            <p class="s2_p1">Interview 指导</p>
            <p class="s2_p2">Interview Practice</p>
            <p class="s2_p3">
              由ROSSO具备丰富申请经验的教师团队为学生进行一对一或一对多面试辅导，内容包含面试内容头脑风暴及全英模拟
            </p>
          </div>
          <div class="s2_box1_cov2">Interview 指导</div>
        </div>

        <div class="s2_box2 s2_box1">
          <div class="s2_box1_cov s2_box1_cov21">
            <p class="s2_p1" style="width: 400px">软实力提升</p>
            <p class="s2_p2" style="width: 400px">Extracurricular Practice</p>
            <p class="s2_p3" style="width: 400px">
              ROSSO
              Music在作品集课程之外，根据国外名校录取偏好，为学员提供Leadership领导力、Negotiation谈判、Public
              Speaking演讲、 Music
              Business音乐商业等背景提升课程，学员可以根据购买的套餐选修相应的课程
            </p>
          </div>
          <div class="s2_box1_cov2">软实力提升</div>
        </div>
        <div class="s2_box1 s2_box3">
          <div class="s2_box1_cov">
            <p class="s2_p1">VIP专属家长会</p>
            <p class="s2_p2">VIP Parents Meeting</p>
            <p class="s2_p3">
              ROSSO学员的家长们将受邀请观摩学生汇演，系统、直观地了解到学员们的学习状况和申请进度
            </p>
          </div>
          <div class="s2_box1_cov2">VIP专属家长会</div>
        </div>
        <div class="s2_box1 s2_box4">
          <div class="s2_box1_cov">
            <p class="s2_p1">作品集录制</p>
            <p class="s2_p2">Portfolio Recording</p>
            <p class="s2_p3">
              ROSSO将为需要录制作品集的学员提供各种类型的录音棚，由专业老师跟棚监督录制，并协助学员安排作品录制所需要的合作乐手
            </p>
          </div>
          <div class="s2_box1_cov2">作品集录制</div>
        </div>
        <div class="s2_box1 s2_box5">
          <div class="s2_box1_cov">
            <p class="s2_p1">夏校申请</p>
            <p class="s2_p2">Summer Program Application</p>
            <p class="s2_p3">
              为需要且适合参加国外夏校的学员申请夏校(或者冬令营等其他同类型短期课程)
            </p>
          </div>
          <div class="s2_box1_cov2">夏校申请</div>
        </div>
      </div>
    </div>
    <!-- 留学特色服务介绍 -->
    <!-- <a
      class="askBtn"
      href="https://www11c1.53kf.com/webCompany.php?arg=10168353&kf_sign=Dc2MjMTY2Ok1NTM4NTkwNDI0MzAwMTM3MjE2ODM1Mw%253D%253D&style=1"
      target="_blank"
    >
      立即咨询
    </a> -->
    <a href="javascript:void(0)" onclick="ydtopenchat()" class="askBtn">立即咨询</a>

    <div class="section3">
      <img src="../assets/musicmaj.png" alt="" style="margin-top: 80px" />

      <div class="s3_main">
        <div class="s3_p1 s3_pic1">
          <p class="s3_text1">现代表演</p>
          <p class="s3_text2">Contemporary Performance</p>
          <div class="s3_cov1">
            <ul>
              <li>• 现代器乐表演</li>
              <li>• 现代声乐表演</li>
              <li>• 音乐剧表演</li>
              <li>• 爵士/即兴表演</li>
            </ul>
          </div>
        </div>

        <div class="s3_p1 s3_pic2">
          <p class="s3_text1">作曲与指挥</p>
          <p class="s3_text2">Composition and Conducting</p>
          <div class="s3_cov1">
            <ul>
              <li>• 古典作曲</li>
              <li>• 古典指挥</li>
            </ul>
          </div>
        </div>

        <div class="s3_p1 s3_pic3">
          <p class="s3_text1">声音艺术与工程</p>
          <p class="s3_text2">Sound Arts and Engineering</p>
          <div class="s3_cov1">
            <ul>
              <li>• 声音设计</li>
              <li>• 音乐工程</li>
            </ul>
          </div>
        </div>
        <div class="s3_p1 s3_pic4">
          <p class="s3_text1">音乐应用与产业</p>
          <p class="s3_text2">Music application and Industry</p>
          <div class="s3_cov1">
            <ul>
              <li>• 音乐教育</li>
              <li>• 音乐治疗</li>
              <li>• 音乐产业与管理</li>
            </ul>
          </div>
        </div>
        <div class="s3_p2 s3_pic5">
          <p class="s3_text1">音乐制作</p>
          <p class="s3_text2">Music production</p>
          <div class="s3_cov1">
            <ul>
              <li>• 歌曲撰写</li>
              <li>• 影视配乐</li>
              <li>• 现代编曲</li>
              <li>• 电子制作</li>
              <li>• 爵士作曲</li>
            </ul>
          </div>
        </div>
        <div class="s3_p2 s3_pic6">
          <p class="s3_text1">古典表演</p>
          <p class="s3_text2">Classical performance</p>
          <div class="s3_cov1">
            <ul>
              <li>• 键盘表演</li>
              <li>• 声乐表演</li>
              <li>• 铜管乐表演</li>
              <li>• 木管乐表演</li>
              <li>• 打击乐表演</li>
              <li>• 弦乐表演</li>
            </ul>
          </div>
        </div>
        <div class="s3_p1 s3_pic7">
          <p class="s3_text1">音乐理论研究</p>
          <p class="s3_text2">Music Theory and Research</p>
          <div class="s3_cov1">
            <ul>
              <li>• 音乐学</li>
              <li>• 音乐理论</li>
              <li>• 音乐史</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <a
        class="askBtn"
        href="https://www11c1.53kf.com/webCompany.php?arg=10168353&kf_sign=Dc2MjMTY2Ok1NTM4NTkwNDI0MzAwMTM3MjE2ODM1Mw%253D%253D&style=1"
        target="_blank"
      >
        更多专业
      </a> -->
      <a href="/major" class="askBtn">更多专业</a>
    </div>

    <div class="section4">
      <img src="../assets/musictutor.png" alt="" style="margin-top: 80px" />

      <div class="btn_box">
        <div id="btn1" class="toggle_btn" :class="{ trangleTrue: flag }" @click="toggle1">
          音乐海外教授
        </div>
        <div id="btn2" class="toggle_btn" :class="{ trangleTrue: !flag }" @click="toggle2">
          音乐带头人
        </div>
      </div>

      <div class="btn_main">
        <div id="prev" @click="prevClick"></div>
        <div class="btn_content" id="btnContent">
          <div id="transCont">
            <div class="square_box">
              <img src="../assets/tutor1.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Isaiah Jackson</p>
              <div class="square"></div>
              <p class="squareNext">伯克利学院</p>
            </div>
            <div class="square_box">
              <img src="../assets/tutor2.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Kati Agócs</p>
              <div class="square"></div>
              <p class="squareNext">新英格兰音乐学院</p>
            </div>
            <div class="square_box">
              <img src="../assets/tutor3.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Jorge Parodi</p>
              <div class="square"></div>
              <p class="squareNext">茱莉亚音乐学院</p>
            </div>
            <div class="square_box">
              <img src="../assets/tutor4.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Reiko Fueting</p>
              <div class="square"></div>
              <p class="squareNext">曼哈顿音乐学院</p>
            </div>
            <div class="square_box">
              <img src="../assets/tutor5.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Jean-Luc Cohen</p>
              <div class="square"></div>
              <p class="squareNext">伯克利音乐学院</p>
            </div>
            <div class="square_box">
              <img src="../assets/tutor6.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Stephen MacLean</p>
              <div class="square"></div>
              <p class="squareNext">伯克利音乐学院</p>
            </div>
            <div class="square_box">
              <img src="../assets/tutor7.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Beth Roberts</p>
              <div class="square"></div>
              <p class="squareNext">The New School</p>
            </div>
            <div class="square_box">
              <img src="../assets/tutor8.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Daniel Abussi</p>
              <div class="square"></div>
              <p class="squareNext">伯克利音乐学院</p>
            </div>
          </div>

          <div id="transCont2">
            <div class="square_box">
              <img src="../assets/rossoarts1.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Shi</p>
              <div class="square"></div>
              <p class="squareNext">约克大学/英国皇家音乐学院(RAM)</p>
              <p class="squareNext">音乐学、声乐表演、声乐、歌剧</p>
            </div>
            <div class="square_box">
              <img src="../assets/rossoarts2.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Huang</p>
              <div class="square"></div>
              <p class="squareNext">英国皇家音乐学院音乐学</p>
              <p class="squareNext">电影配乐、音乐制作</p>
            </div>
            <div class="square_box">
              <img src="../assets/rossoarts3.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Keren</p>
              <div class="square"></div>
              <p class="squareNext">英国爱丁堡大学</p>
              <p class="squareNext">钢琴、演奏、音乐学、乐理</p>
            </div>
            <div class="square_box">
              <img src="../assets/rossoarts4.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Ray</p>
              <div class="square"></div>
              <p class="squareNext">伯克利音乐学院</p>
              <p class="squareNext">音乐制作、影视配乐、作曲</p>
            </div>
            <div class="square_box">
              <img src="../assets/rossoarts5.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Zhang</p>
              <div class="square"></div>
              <p class="squareNext">美国曼哈顿音乐学院</p>
              <p class="squareNext">古典声乐(歌剧)</p>
            </div>
            <div class="square_box">
              <img src="../assets/rossoarts6.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Angela</p>
              <div class="square"></div>
              <p class="squareNext">美国州立密歇根大学</p>
              <p class="squareNext">小提琴</p>
            </div>
            <div class="square_box">
              <img src="../assets/rossoarts7.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Tommy</p>
              <div class="square"></div>
              <p class="squareNext">英国皇家威尔士音乐学院</p>
              <p class="squareNext">音乐剧，流行演唱</p>
            </div>
            <div class="square_box">
              <img src="../assets/rossoarts8.png" alt="" />
              <div class="img_cov_line"></div>
              <p class="squarePre">Libby Chen</p>
              <div class="square"></div>
              <p class="squareNext">德国国立纽伦堡音乐学院</p>
              <p class="squareNext">大提琴演奏</p>
            </div>
          </div>
        </div>

        <div id="next" @click="nextClick"></div>
      </div>
      <!-- <a
        class="askBtn"
        href="https://www11c1.53kf.com/webCompany.php?arg=10168353&kf_sign=Dc2MjMTY2Ok1NTM4NTkwNDI0MzAwMTM3MjE2ODM1Mw%253D%253D&style=1"
        target="_blank"
      >
        更多导师
      </a> -->
      <a href="/teacher" class="askBtn">更多导师</a>
    </div>

    <ReviewForm></ReviewForm>
    <!-- <div class="section5">
      <div class="s5_title">
        限时免费领取作品集试听课！ROSSO助你早日实现名校梦！
      </div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="formInline.name" placeholder="请输入您的姓名" id="nameRule"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formInline.tel" placeholder="请输入您的手机号" id="telRule"></el-input>
        </el-form-item>
        <el-form-item label="意向专业">
          <el-select v-model="formInline.major" placeholder="意向专业">
            <el-option label="现代器乐表演" value="现代器乐表演"></el-option>
            <el-option label="现代声乐表演" value="现代声乐表演"></el-option>
            <el-option label="音乐制作" value="音乐制作"></el-option>
            <el-option label="音乐剧表演" value="音乐剧表演"></el-option>
            <el-option label="作曲与指挥" value="作曲与指挥"></el-option>
            <el-option label="古典器乐表演" value="古典器乐表演"></el-option>
            <el-option label="声乐设计" value="声乐设计"></el-option>
            <el-option label="音乐产业/管理" value="音乐产业/管理"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即预约</el-button>
        </el-form-item>
      </el-form>
    </div> -->

    <!-- v-for="(item,index) in schoolData" :key="index" -->
    <div class="section6">
      <img src="../assets/hotMusColl.png" alt="" style="margin-top: 80px" />
      <div class="school_box" id="schoolBox">
        <div class="schoolItemBox" v-for="(item, index) in schoolData" :key="index" @click="shchoolDetail(item.id)">
          <img :src="'http://image.rossoarts.com/' + item.logo" alt="" class="schoolimg">
          <p class="schoolp1">{{ item.cname }}</p>
          <p class="schoolp2">{{ item.ename }}</p>
        </div>

      </div>

      <!-- <a href="javascript:void(0)" onclick="ydtopenchat()" class="moreSchoolBtn">了解更多专业</a> -->
      <a href="/colleges" class="moreSchoolBtn">了解更多院校</a>


    </div>

    <div class="section17">
      <img src="../assets/s7title.png" alt="" style="margin-top: 80px" />
      <div class="block2">


        <el-carousel trigger="click" height="640px" class="s7all_box" indicator-position="none" :autoplay="false">
          <el-carousel-item v-for="item in s7banner" :key="item.id" class="s7item_box">
            <div class="item_left">
              <p class="pName">{{ item.studentName }}</p>
              <p class="ptype1">申请方向</p>
              <p class="ptype2">{{ item.direction }}</p>
              <p class="ptype1">录取院校</p>
              <p class="ptype2">{{ item.college }}</p>
              <p class="ptype1">导师评价</p>
              <p class="ptype2">{{ item.jarjg }}</p>
            </div>
            <div class="s7_right_img">

            </div>

          </el-carousel-item>
        </el-carousel>
      </div>

    </div>

    <div class="section8">
      <img src="../assets/s8title.png" alt="" style="margin-top: 80px" />

      <el-carousel :interval="4000" type="card" height="400px" width="10%" class="s8allBox" :autoplay="false">
        <el-carousel-item class="s8bannerBox1">

        </el-carousel-item>
        <el-carousel-item class="s8bannerBox2">

        </el-carousel-item>
        <el-carousel-item class="s8bannerBox3">

        </el-carousel-item>
        <el-carousel-item class="s8bannerBox4">

        </el-carousel-item>
        <el-carousel-item class="s8bannerBox5">

        </el-carousel-item>
      </el-carousel>



    </div>


    <TabBottom></TabBottom>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import TabBottom from '@/components/TabBottom.vue'
import ReviewForm from '@/components/ReviewForm.vue'
import axios from "axios";


export default {
  name: "HomeView",
  components: {
    // HelloWorld
    TabBottom,
    ReviewForm
  },
  data() {
    return {
      s7banner: [
        {
          id: 1,
          studentName: "C同学",
          direction: "【硕士】传统作曲/音乐制作/影视配乐",
          college: "天普大学/朗格音乐学校",
          jarjg: " 基础非常扎实的作曲系学生，作品能体现不错的学术功底，但有时想法非常局限， 作品呈现的感觉很难体现个人的音乐风格。申请大学竞争力而言中等偏上 "
        },
        {
          id: 2,
          studentName: "S同学",
          direction: "【硕士】作曲/音乐制作",
          college: "伯克利音乐学院",
          jarjg: "作曲相当有天赋的学生，在托福基础略有不足的情况下录取了自己的梦校。在本科学习中基本以传统作曲为主，但在本科毕业后开始积攒行业经验，在影视配乐方面较为感兴趣。"
        },
        {
          id: 3,
          studentName: "L同学",
          direction: "【本科】音乐管理/音乐制作",
          college: "伯克利音乐学院",
          jarjg: "拥有非常扎实的古典钢琴功底，有可以匹配古典音乐学生的演奏实力。考虑到性格和个人追求方面和音乐的结合，选择了音乐管理专业"
        },
        {
          id: 4,
          studentName: "X同学",
          direction: "【本科】小提琴",
          college: "皇家音乐学院/爱尔兰音乐学院",
          jarjg: "有一定天赋且有足够时长的精深练习，音乐性强；为她提供了和RAM小提琴教授接触的机会，并且获得了教授推荐信"
        },
        {
          id: 5,
          studentName: "J同学",
          direction: "【本科】作曲/音乐制作",
          college: "伯克利音乐学院",
          jarjg: "从小学习作曲以及古典钢琴演奏，但对不同⻛格的音乐始终保持非常大的热情。从更早的传统作曲到入学Berklee后的EDM音乐制作。"
        },
        {
          id: 6,
          studentName: "G同学",
          direction: "【硕士】音乐教育",
          college: "约克大学/巴德学院音乐学院",
          jarjg: "报名时间相对较晚，作品准备时间较短，但学生态度认真积极，配合老师授课，对于音乐教育有自己的见解和看法。"
        }

      ],
      imgs: [],
      transindex: 0,
      flag: true,
      formInline: {
        name: '',
        tel: '',
        major: ''
      },
      schoolData: []
    };
  },
  created() {
    this.getMusicSchool()
    // console.log("2222");

  },


  methods: {
    onSubmit() {
      var currentURL = window.location.href;
      var status = ''
      // 检查 URL 中是否包含某些关键词，来判断使用的搜索引擎
      if (currentURL.includes("google.com")) {
        status = "用户正在使用 Google 搜索引擎"
      } else if (currentURL.includes("bing.com")) {
        status = "用户正在使用 Bing 搜索引擎"
      } else if (currentURL.includes("yahoo.com")) {
        status = "用户正在使用 Yahoo 搜索引擎"
      } else if (currentURL.includes("baidu.com")) {
        status = "用户正在使用 百度 搜索引擎"
      } else {
        status = "无法确定用户使用的搜索引擎"
      }

      var patt = /^1[3-9][0-9]{9}$/

      if (this.formInline.name == "" || this.formInline.tel == "") {
        // console.log("姓名和手机号不能为空");
        this.$message.error('姓名和手机号不能为空');

      } else {
        // console.log("不是空");

        if (patt.test(this.formInline.tel)) {

          // console.log("可以发请求");
          // console.log(this.formInline);
          axios.post("https://public.rossoarts.com/api/v1/submitForm", {
            "name": this.formInline.name,
            "tel": this.formInline.tel,
            "major": this.formInline.major,
            "url": window.location.href,
            "status": status,
          }).then((data) => {
            // console.log(data,"response");
            this.$message({
              message: "提交成功",
              type: 'success'
            });
          }).catch(function (error) {
            console.log(error, "error");
          });

        } else {
          console.log("手机号格式不正确");
          this.$message.error('手机号格式不正确');

        }

      }


    },
    getMusicSchool() {
      axios
        .get("https://public.rossoarts.com/api/v1/getMusicSchool?list=0&count=8")
        .then((data) => {
          // console.log(data);
          this.schoolData = data.data.data

          // console.log(this.schoolData);

        });
    },
    shchoolDetail(val) {
      console.log(val);
      this.$router.push(`/collegeDetail?id=${val}`)
    },
    nextClick() {
      console.log("333");
      this.transindex++;
      if (this.transindex > 1) {
        this.transindex = 1;
      }
      var transCont = document.getElementById("transCont");
      transCont.style.transform = `translateX(${-1000 * this.transindex}px)`;
      transCont2.style.transform = `translateX(${-1000 * this.transindex}px)`;
    },
    prevClick() {
      console.log("111");
      this.transindex--;
      if (this.transindex < 0) {
        this.transindex = 0;
      }
      // var transCont = document.getElementById("transCont");
      transCont.style.transform = `translateX(${-1000 * this.transindex}px)`;
      transCont2.style.transform = `translateX(${-1000 * this.transindex}px)`;
    },
    toggle1() {
      this.flag = true;
      btn1.style.backgroundColor = "rgb(44,166,224)";
      btn2.style.backgroundColor = "white";
      this.transindex = 0;
      transCont.style.transform = `translateX(${-1000 * this.transindex}px)`;
      transCont.style.display = "flex";
      transCont2.style.display = "none";
    },
    toggle2() {
      this.flag = false;
      btn2.style.backgroundColor = "rgb(44,166,224)";
      btn1.style.backgroundColor = "white";
      this.transindex = 0;
      transCont2.style.transform = `translateX(${-1000 * this.transindex}px)`;
      transCont.style.display = "none";
      transCont2.style.display = "flex";
    },
  },

};
</script>

<style scoped lang="less">
.s8allBox {
  width: 1120px;
  // border: 1px solid red;
  margin: 20px auto;
}

.section8 {
  margin: 0 0 40px 0;
  // border: 1px solid #000;

  .s8bannerBox1 {

    background: url(../assets/s8banner1.png) no-repeat 50%;
    background-size: 50%;

  }

  .s8bannerBox2 {

    background: url(../assets/s8banner2.png) no-repeat 50%;
    background-size: 50%;
  }

  .s8bannerBox3 {

    background: url(../assets/s8banner3.png) no-repeat 50%;
    background-size: 50%;
  }

  .s8bannerBox4 {

    background: url(../assets/s8banner4.jpg) no-repeat 50%;
    background-size: 50%;
  }

  .s8bannerBox5 {

    background: url(../assets/s8banner5.png) no-repeat 50%;
    background-size: 50%;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

}



.block2 {
  // display: flex;
  // justify-content: center;

  text-align: center;
  // border: 1px solid #000;
}

.s7all_box {
  width: 1246px;
  // height: 400px;
  // border: 1px solid red;
  margin: 0 auto;
  // display: flex;
  // justify-content: center;
  position: relative;
}

.s7item_box {
  width: 1000px;
  height: 400px;
  display: flex;
  justify-content: space-between;
  // border: 1px solid blue;
  position: absolute;
  top: calc(50% - 300px);
  left: calc(50% - 500px);

  .item_left {
    width: 420px;
    height: 400px;
    // border: 1px solid #000;
    text-align: left;

    .pName {
      color: #333;
      font-size: 32px;
      padding: 20px 0;
    }

    .ptype1 {
      font-size: 22px;
      color: #333;
      margin: 10px 0 0;
    }

    .ptype2 {
      font-size: 18px;
      color: #333;
      margin: 10px 0 0;
    }
  }
}

.s7_right_img {
  width: 356px;
  height: 368px;
  // border: 1px solid #000;
}

.s7item_box:nth-of-type(1) {
  .s7_right_img {
    background: url(../assets/s7b1.png);
  }

}

.s7item_box:nth-of-type(2) {
  .s7_right_img {
    background: url(../assets/s7b2.png);
  }

}

.s7item_box:nth-of-type(3) {
  .s7_right_img {
    background: url(../assets/s7b3.png);
  }

}

.s7item_box:nth-of-type(4) {
  .s7_right_img {
    background: url(../assets/s7b4.png);
  }

}

.s7item_box:nth-of-type(5) {
  .s7_right_img {
    background: url(../assets/s7b5.png);
  }

}

.s7item_box:nth-of-type(6) {
  .s7_right_img {
    background: url(../assets/s7b6.png);
  }

}

.section17 {
  width: 100%;
  height: 640px;
  // border: 1px solid #000;
  text-align: center;
  background: url(../assets/s7bgc.png) no-repeat 50%;
}

.section6 {
  background: url(../assets/s6bgc.png) no-repeat 50%;
  width: 100%;
  height: 889px;
  margin-top: -5px;

  .moreSchoolBtn {
    display: block;
    width: 190px;
    height: 52px;
    line-height: 52px;
    background: #fff;
    border-radius: 10px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
  }

  .school_box {
    margin: 40px auto 0;
    width: 1120px;
    height: 580px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;

    // border: 1px solid #000;
    .schoolItemBox {
      width: 280px;
      height: 235px;

      .schoolimg {
        width: 158px;
        border-radius: 50%;
        transition: all 0.5s;

      }

      .schoolp1 {
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        margin-top: 30px;
        transition: all 0.5s;
      }

      .schoolp2 {
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        margin-top: 5px;
        transition: all 0.5s;
      }
    }

  }
}

.moreSchoolBtn:hover {
  color: rgb(44, 166, 224);
}

.schoolItemBox:hover .schoolimg {
  transform: translateY(-10px);
  // border: 1px solid purple;
  box-shadow: 0 0 30px 1px purple;
}

.schoolItemBox:hover .schoolp1 {
  // margin-top: 80px;
  transform: translateY(10px);
}

.schoolItemBox:hover .schoolp2 {
  // margin-top: 80px;
  transform: translateY(10px);
}

.section5 {
  width: 100%;
  height: 184px;
  background: url(../assets/s5bgc.png);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: white;

}

.s5_title {
  // margin: 40px auto;
  width: 100%;
  font-size: 20px;
}

.demo-form-inline {
  color: white;
}

.square_box {
  position: relative;
  height: 380px;
  transition: all 0.5s;
  background-color: white;
}

.img_cov_line {
  width: 230px;
  height: 230px;
  border: 1px solid transparent;
  position: absolute;
  top: 10px;
  left: calc(50% - 115px);
}

.square_box:hover .img_cov_line {
  border-color: white;
}

.square_box:hover {
  background-color: #000;
}

.square_box:hover .square {
  background-color: rgb(44, 166, 224);
}

.square_box:hover .squarePre {
  color: white;
}

.square_box:hover .squareNext {
  color: white;
}

.squarePre {
  font-size: 20px;
  color: #333;
  padding: 15px 0 0;
}

.squareNext {
  font-size: 14px;
  color: #333;
}

.square {
  width: 8px;
  height: 8px;
  background-color: #333;
  margin: 8px auto;
}

#btn1 {
  background-color: rgb(44, 166, 224);
}

.transClass:hover {
  cursor: pointer;
  background-color: #2ca6e0;
}

.trangleTrue {
  position: relative;
}

.trangleTrue::after {
  width: 0px;
  height: 0px;
  content: "";
  border: 10px solid transparent;
  border-top-color: #2ca6e0;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
}

.btn_main {
  width: 1120px;
  height: 380px;
  // border: 1px solid #000;
  margin: 40px auto;
  display: flex;

  #prev {
    width: 60px;
    height: 380px;
    // border: 1px solid #000;
    position: relative;
    background-color: #e8e8e8;
    cursor: pointer;
  }

  #prev:hover {
    background-color: rgb(44, 166, 224);
  }

  #prev::after {
    width: 8px;
    height: 8px;
    content: "";
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(45deg);
    color: #000;
    position: absolute;
    top: calc(50% - 4px);
  }

  #next::after {
    width: 8px;
    height: 8px;
    content: "";
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg);
    color: #000;
    position: absolute;
    top: calc(50% - 4px);
  }

  .btn_content {
    width: 1000px;
    height: 380px;
    // border: 1px solid #000;
    // display: flex;
    overflow: hidden;

    img {
      width: 250px;
      height: 250px;
    }

    #transCont {
      display: flex;
      transition: all 0.5s;
    }

    #transCont2 {
      display: none;
      transition: all 0.5s;
    }
  }

  #next {
    width: 60px;
    height: 380px;
    // border: 1px solid #000;
    position: relative;
    background-color: #e8e8e8;
    cursor: pointer;
  }

  #next:hover {
    background-color: rgb(44, 166, 224);
  }
}

.btn_box {
  display: flex;
  margin: 20px auto;
  width: 1120px;
  justify-content: space-between;

  .toggle_btn {
    width: 556px;
    height: 70px;
    // border: 1px solid #000;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    line-height: 70px;
    cursor: pointer;
  }
}

.section4 {
  // border: 1px solid #000;
  margin: 40px 0 0 0;
  background-color: #f6f6f6;
  padding: 0 0 50px 0;
}

.s3_main {
  width: 1120px;
  height: 834px;
  // border: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
  position: relative;

  .s3_p1,
  .s3_p2 {
    width: 368px;
    height: 268px;
    // border: 1px solid #000;
    position: relative;
    overflow: hidden;

    .s3_text1 {
      font-size: 28px;
      color: #fff;
      margin: 90px 0 0 0;
      transition: all 0.5s;
    }

    .s3_text2 {
      font-size: 16px;
      color: #fff;
    }

    .s3_cov1 {
      position: absolute;
      top: 270px;
      left: calc(50% - 170px);
      transition: all 0.5s;
      font-size: 14px;
      color: #333;

      ul {
        width: 340px;
        height: 100px;
        background-color: white;
        opacity: 0.8;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        position: absolute;
        padding: 10px;
        box-sizing: border-box;

        // bottom: -100px;
        li {
          margin: 10px;
        }
      }
    }
  }

  .s3_p2 {
    .s3_cov1 {
      left: calc(50% - 350px);

      ul {
        width: 700px;
      }
    }
  }

  .s3_p1:hover .s3_cov1 {
    top: 155px;
  }

  .s3_p1:hover .s3_text1 {
    margin: 65px 0 0 0;
  }

  .s3_p2:hover .s3_cov1 {
    top: 155px;
  }

  .s3_p2:hover .s3_text1 {
    margin: 65px 0 0 0;
  }

  // 88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888
  .s3_p2 {
    width: 744px;
    height: 268px;
    // border: 1px solid red;
  }

  .s3_pic1 {
    background: url(../assets/s3_pic1.png);
  }

  .s3_pic2 {
    background: url(../assets/s3_pic2.png);
  }

  .s3_pic3 {
    background: url(../assets/s3_pic3.png);
  }

  .s3_pic4 {
    background: url(../assets/s3_pic4.png);
  }

  .s3_pic5 {
    background: url(../assets/s3_pic5.png);
  }

  .s3_pic6 {
    background: url(../assets/s3_pic6.png);
  }

  .s3_pic7 {
    background: url(../assets/s3_pic7.png);
  }
}

.s2_main {
  width: 1120px;
  height: 556px;
  // border: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
}

.s2_box1 {
  width: 368px;
  height: 268px;
  // border: 1px solid #000;
  background: url(../assets/s2_pic1.png);
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  overflow: hidden;

  .s2_box1_cov {
    width: 0px;
    height: 268px;
    background-color: white;
    opacity: 0.8;
    transition: all 0.5s;
    color: #333;
    font-size: 16px;

    .s2_p1 {
      width: 200px;
      margin-top: 40px;
      // font-weight: bold;
      color: #000;
    }

    .s2_p2 {
      width: 200px;
      // margin-top: 20px;
      color: #000;
    }

    .s2_p3 {
      width: 200px;
      height: 400px;
      text-align: center;
      // border: 1px solid #000;
      margin: 20px auto;
      padding: 20px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.s2_box1_cov2 {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: rgba(0, 0, 0, 0.57);
  position: absolute;
  bottom: 0px;
  color: white;
}

.s2_box1:hover .s2_box1_cov {
  width: 200px;
}

.s2_box2:hover .s2_box1_cov21 {
  width: 400px;
}

.s2_box1:hover .s2_box1_cov2 {
  display: none;
}

.s2_box2 {
  width: 746px;
  height: 268px;
  // border: 1px solid #000;
  background: url(../assets/s2_pic2.png);
}

.s2_box3 {
  background: url(../assets/s2_pic3.png);
}

.s2_box4 {
  background: url(../assets/s2_pic4.png);
}

.s2_box5 {
  background: url(../assets/s2_pic5.png);
}

.home {
  margin-top: 111px; //router-view
  overflow-x: hidden;
}

.int_ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 61px 63px;
  margin: 0 auto;
  width: 1120px;

  // border: 1px solid #000;
  .img1_div {
    margin: 20px auto;
    width: 54px;
    height: 54px;

    // border: 1px solid #000;
    background: url("../assets/int_one.png");
    background-position: 50% 50%;
    background-size: 100%;
  }

  .img2_div {
    margin: 20px auto;
    width: 54px;
    height: 54px;

    // border: 1px solid #000;
    background: url("../assets/书本1.png");
    background-position: 50% 50%;
    background-size: 100%;
  }

  .img3_div {
    margin: 20px auto;
    width: 54px;
    height: 54px;

    // border: 1px solid #000;
    background: url("../assets/城堡1.png");
    background-position: 50% 50%;
    background-size: 100%;
  }

  .img4_div {
    margin: 20px auto;
    width: 54px;
    height: 54px;

    // border: 1px solid #000;
    background: url("../assets/音乐1.png");
    background-position: 50% 50%;
    background-size: 100%;
  }

  .p1 {
    font-size: 18px;
    color: #333;
    margin: 20px 0 0 0;
  }

  .p2 {
    margin: 0 30px;
    font-size: 14px;
    color: #666;
    margin: 25px 0 0 0;
    padding: 0 30px;
  }
}

.int_ul li {
  width: 273px;
  height: 251px;
  border: 1px solid #e6e6e6;
  margin: 0 5px;
}

.int_ul li:hover {
  background-color: rgb(44, 166, 224);
}

.int_ul li:hover .img1_div {
  background: url("../assets/勋章2.png");
  background-position: 50% 50%;
  background-size: 100%;
}

.int_ul li:hover .img2_div {
  background: url("../assets/书本2.png");
  background-position: 50% 50%;
  background-size: 100%;
}

.int_ul li:hover .img3_div {
  background: url("../assets/城堡2.png");
  background-position: 50% 50%;
  background-size: 100%;
}

.int_ul li:hover .img4_div {
  background: url("../assets/音乐2.png");
  background-position: 50% 50%;
  background-size: 100%;
}

.int_ul li:hover .p1 {
  color: white;
}

.int_ul li:hover .p2 {
  color: white;
}

.section11 {
  background-color: rgb(246, 246, 246);
  padding: 0 0 60px 0;
  // border: 1px solid #000;
}

.section_box {
  width: 1120px;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.secton1_ul {
  width: 560px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto;
  list-style: none;
}

.secton1_ul li {
  width: 280px;
  height: 280px;
  // border: 1px solid #000;
  text-align: left;
  padding: 27px;
  box-sizing: border-box;
  position: relative;

  .s1_p1 {
    font-size: 52px;
    font-style: italic;
    font-weight: bold;
    color: #e9e9e9;
  }

  .s1_p2 {
    font-size: 20px;
    color: #333;
    margin: -30px 0 0;
    // font-weight: bold;
    // position: absolute;
    // top:0;
  }

  .s1_p3 {
    font-size: 12px;
    color: #999;
    margin: 10px 0 0;
  }

  .s1_p4 {
    font-size: 14px;
    color: #333;
    margin: 30px 0 0;
    transition: all 0.4s;
    line-height: 23px;
  }

  .trangle {
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    top: 45px;
    right: 4px;
    transform: rotate(45deg);
    transition: all 0.4s;
    z-index: 10;
  }
}

.secton1_ul:hover .s1_p1 {
  color: rgb(223, 243, 252);
}

.secton1_ul:hover .s1_p2 {
  color: rgb(44, 166, 224);
}

.secton1_ul:hover .s1_p3 {
  color: rgb(223, 243, 252);
}

.secton1_ul:hover .s1_p4 {
  color: rgb(44, 166, 224);
  margin: 25px 0 0;
}

.secton1_ul:hover .trangle {
  right: -10px;
}

.secton1_ul2:hover .trangle {
  left: -10px;
  right: 0px;
}

.type_two {
  background: url(../assets/type_two.png);
  background-position: 50%;
  background-size: 100%;
  transition: all 0.4s;
}

.type_one {
  background-color: white;
}

.cover_liner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid rgb(237, 238, 241);
  transition: width height 1s;
  // transition-property:width height;
  // transition-duration:0.4s;
}

.secton1_ul:hover .cover_liner {
  width: 90%;
  height: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.secton1_ul:hover .type_two {
  background-size: 120%;
  background-position: 50%;
}

.type4 {
  background: url(../assets/type4.png);
  background-position: 50%;
  transition: all 0.4s;
  background-size: 100%;
}

.type5 {
  background: url(../assets/type5.png);
  background-position: 50%;
  transition: all 0.4s;
  background-size: 100%;
}

.type7 {
  background: url(../assets/type7.png);
  background-position: 50%;
  transition: all 0.4s;
  background-size: 100%;
}

.type10 {
  background: url(../assets/type10.png);
  background-position: 50%;
  transition: all 0.4s;
  background-size: 100%;
}

.type12 {
  background: url(../assets/type12.png);
  background-position: 50%;
  transition: all 0.4s;
  background-size: 100%;
}

.askBtn {
  width: 190px;
  height: 52px;
  line-height: 52px;
  background: #2ca6e0;
  border-radius: 10px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin: 60px auto 0;
  display: block;
  cursor: pointer;
  text-decoration: none;
}

.block {
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
    background: url(../assets/banner-two.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .el-carousel__item:nth-child(2n-1) {
    background-color: #d3dce6;
    background: url(../assets/banner-one.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .el-carousel__item:nth-child(3n) {
    background-color: #d3dce6;
    background: url(../assets/banner-one.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .el-carousel__item:nth-last-child(1) {
    background: url(../assets/banner-three.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.askBtn:hover {
  background-color: rgb(18, 118, 166);
}</style>